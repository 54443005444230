/* Reset default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Set base font and colors */
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #1c1b55;
}

/* Container for content */
.container {
  width: 80%;
  margin: auto;
  overflow: hidden;
}

/* Header styles */


/* Main content area */
main {
  padding: 20px 0;
}

/* Footer styles */
footer {
  padding: 20px;
  margin-top: 20px;
  color: #ffffff;
  background-color: #35424a;
  text-align: center;
}

/* Responsive design */
@media(max-width: 768px) {
  header #branding,
  header nav,
  header nav li {
    float: none;
    text-align: center;
    width: 100%;
  }

  header {
    padding-bottom: 20px;
  }
}

.banner {
  border-radius: 15px;
  background-color: #2e1d7e;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  padding: 20px;
  margin: 100px;
}

.banner-title {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.banner-subtitle {
  font-size: 1.2rem;
  max-width: 600px;
}

